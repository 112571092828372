
.form-wizard > ul > li.completed a span {
    background: #0088CE !important;
    color: #fff !important;
    -webkit-box-shadow: 0px 0px 0px 5px #0088CE !important;
    box-shadow: 0px 0px 0px 5px #0088CE !important;
}

.form-wizard > ul > li.completed a span {
    background: #0088CE !important;
    color: #fff !important;
    -webkit-box-shadow: 0px 0px 0px 5px #0088CE !important;
    box-shadow: 0px 0px 0px 5px #0088CE !important;
}

.form-wizard > ul > li.completed a {
    color: #0088CE !important;
}

.form-wizard .steps-progress .progress-indicator{
    background: #0088CE !important;
}

@media only screen and (max-width: 768px) {
    .form-wizard .tab-content {
        margin: 35px 0px !important;
    }
    .form-wizard > ul > li a {
        font-size: 10px !important;
    }
    .label-select{
        text-align: left !important;
    }
}