.btn-group button {
  background-color: white; /* Green background */
  border: 1px solid #303641; /* Green border */
  color: #303641; /* White text */
  padding: 5px 10px; /* Some padding */
  cursor: pointer; /* Pointer/hand icon */
  float: left; /* Float the buttons side by side */
  outline:0;
}

.btn-group button:disabled {
  border: 1px solid #ddd !important;
  color: #ddd !important;
}

.btn-group button:not(:last-child) {
  border-right: none; /* Prevent double borders */
}

/* Clear floats (clearfix hack) */
.btn-group:after {
  content: "";
  clear: both;
  display: table;
}

/* Add a background color on hover */
.btn-group button:not(:disabled):hover, .btn-group button.selected {
  background-color: #303641;
  color: white
}