.form-control.is-group {
  width: auto;
  display: inline-block;
  clear: both;
}
label.pre-label {
  margin-left: 10px;
  text-transform: lowercase;
}
label.pre-label::first-letter {
  text-transform: uppercase;
}
label.bold {
  font-size: 14px;
}
.selector-content {
  display: inline-block;
  min-width: 220px;
  margin: 5px 0px 15px 0px;
}
.switcher-content {
  text-align: right;
  display: inline-block;
}
.input-content {
  display: inline-block;
  width: calc(100% - 130px);
}
.flexed {
  display: inline-flex;
  flex-direction: column-reverse;
}
.flexed .selector-content {
  margin-left: -10px;
}
.flexed .pre-label {
  margin-left: 0px;
}
