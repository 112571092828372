.form-control.is-group{
    width: auto;
    display: inline-block;
    clear: both;
}
label.pre-label{
    margin-right: 15px;
}
label.bold{
    /* font-size: 14px; */
}