

.form-group{
    float: left;
}

.panel-primary{
    float: left;
    width: 100%;
}

.control-label{
    margin-top: 7px;
}

.form-control{
    margin-bottom: 15px
}
.clearfix{
    clear: both;
}

label[for="poron"], label[for="propulsorAp"]{
    margin: 0px;
}
.row.page {
    display: flex;
    flex-wrap: wrap;
}