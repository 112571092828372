.alert {
  display: block;
  width: 100%;
  position: fixed;
  top: 20px;
  z-index: 9;
  animation: fadeinout .5s linear forwards;
  text-align: center;
}

.alert .content{
  margin: 0 auto;
  width: auto;
  text-align: left;
  padding: 15px;
}

@-webkit-keyframes fadeinout {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fadeinout {
  0% { opacity: 0; }
  100% { opacity: 1; }
}