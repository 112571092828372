.mainLoading{
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  z-index: 9;
}

.modal{
  background-color: rgba(0,0,0,0.5);
}