.rdt_TableHeader{
  display: inline-block;
  width: 50%;
}

.datatable-container.short .rdt_TableHeader{
  display: none;
}

.datatable-container:not(.short) .rdt_TableHeader + header, .datatable-container:not(.short) .selector-container{ 
  display: inline-block;
  float: right;
  text-align: right;
  width: 50%;
}

.datatable-container{
  position: relative;
}

.datatable-container.short{
  padding-top: 15px;
}

.datatable-container input[type="search"]{
  height: 38px;
}

.datatable-container:not(.short) .selector-container{
  margin-bottom: 15px;
  position: absolute;
  right: 340px;
  z-index: 3;
  top: 60px;
  display: flex;
  justify-content: flex-end;
}

.divFullScreen{
  position: relative;
  background: white;
}

.button-fullscreen{
  position: absolute;
  right: 15px;
  top: -45px;
  cursor: pointer;
  font-size: 20px;
}

.button-fullscreen:hover{
  color: black;
}

.datatable-container.short .ffHmKg{
  padding: 0 16px;
}
.datatable-container.short #search{
  width: 100%;
}

.nopadd{
  border-left: 1px solid rgba(0,0,0,.12);
  padding: 0px !important;
  position: relative !important;
}

.customModal{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
}


.customModal .modal-dialog{
  padding: 0px;
  width: 100% !important;
}

.customModal .modal-body{
  padding: 0px;
  width: 100%;
  height: 100vh;
}

@media (max-width: 950px) {
  .selector-container{
    position: relative;
    right: 0px;
  }
}