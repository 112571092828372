.text-capitalize{
    text-transform: capitalize;
    width: 50%;
}

#mailContent h2{
    font-size: 18px;
}

.delivery-list {
    display: flex;
}