@import url('./jquery-ui-1.10.3.custom.min.css');
@import url('./font-icons/entypo/css/entypo.css');
@import url('./font-icons/entypo/css/animation.css');
@import url('http://fonts.googleapis.com/css?family=Noto+Sans:400,700,400italic');
@import url('./neon-forms.css');
@import url('./neon.css');
@import url('./custom.css');

.xat {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 9;
}
