.image-selectable{
    height: 217px;
    margin-bottom: 25px;
    cursor: pointer;
}

.image-selectable .image-container > img.active{
    border: 2px solid #303641;
}

.image-container {
    position: relative;
    height: 70%;
    display: inline-block;
}